<template>
  <div><div class="title">商品税率表</div><div style="text-align: center; line-height: 20px; margin-top: 10px;">
    发布时间：2022-04-11 20:24:13
  </div><div data-v-0ab10f90="" style="font-size: 14px;"><div class="clearfix">&nbsp;</div>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <h2>1.《跨境电商综合税表》适用跨境电商综合税  2.《中华人民共和国进境物品进口税率表》适用行邮税；</h2>
    <h3><strong>《跨境电商综合税表》适用跨境电商综合税的商品税率</strong></h3>
    <p>根据跨境电子商务零售进口税收政策，个人单笔交易限值人民币5000元，个人年度交易限值人民币26000元。<br>在限值以内进口的跨境电子商务零售进口商品，关税税率暂设为0%；进口环节增值税、消费税按法定应纳税额的70%征收。计算规则如下：<br>税费=购买单价 x 件数 x 跨境电商综合税率<br>跨境电商综合税率 =[（消费税率 + 增值税率）/（1-消费税率）]×70%</p>

    <!-- <h3><strong>《中华人民共和国进境物品进口税率表》适用行邮税的商品税率</strong></h3> -->
    <table border="1" width="900" cellspacing="0" cellpadding="0">
      <tbody>
        <tr>
          <td>
            <p><strong>税号</strong></p>
          </td>
          <td>
            <p><strong>物品名称</strong></p>
          </td>
          <td>
            <p><strong>税率（%）</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <p>1</p>
          </td>
          <td>
            <p>书报、刊物、教育用影视资料；计算机、视频摄录一体机、数字照相机等信息技术产品；食品、饮料；金银；家具；玩具，游戏品、节日或其他娱乐用品</p>
          </td>
          <td>
            <p>15</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>2</p>
          </td>
          <td>
            <p>运动用品（不含高尔夫球及球具）、钓鱼用品；纺织品及其制成品；电视摄像机及其他电器用具；自行车；税目1、3中未包含的其他商品</p>
          </td>
          <td>
            <p>30</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>3</p>
          </td>
          <td>
            <p>烟、酒；贵重首饰及珠宝玉石；高尔夫球及球具；高档手表；化妆品</p>
          </td>
          <td>
            <p>60</p>
          </td>
        </tr>
      </tbody>
    </table>
    <p>注：税目3 所列商品的具体范围与消费税征收范围一致。</p>
    <p>&nbsp;</p>
    <h3><strong>&nbsp;</strong></h3>
    <table border="1" width="900" cellspacing="0" cellpadding="0">
      <tbody>
        <tr>
          <td class="et3" colspan="5" width="716" height="66">跨境电商综合税说明</td>
        </tr>
        <tr>
          <td class="et4" colspan="2" rowspan="2" width="440" height="133">类目</td>
          <td class="et6" colspan="2" width="144">一般贸易税【外贸】</td>
          <td class="et8" width="132">跨境电商综合税【直邮9610and保税1210】（%）</td>
        </tr>
        <tr>
          <td class="et8" width="72">增值税率（%）</td>
          <td class="et8" width="72">消费税率（%）</td>
          <td class="et8" width="132">新跨境电商综合税（%）</td>
        </tr>
        <tr>
          <td class="et11" width="108" height="33">婴儿奶粉</td>
          <td class="et11" width="332">婴儿奶粉</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="108" height="33">成人奶粉</td>
          <td class="et11" width="332">成人奶粉</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="108" height="33">纸尿裤</td>
          <td class="et11" width="332">纸尿裤</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="108" height="33">保健品</td>
          <td class="et11" width="332">保健品</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" rowspan="25" width="108" height="831">食品</td>
          <td class="et11" width="332">麦片、薯片、膨化食品</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">巧克力</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">水果干</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">坚果类</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">咖啡</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">矿泉水</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">汽水</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">液态奶</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">果汁饮料</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">口香糖</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">软糖</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">黑糖</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">蔬菜泥</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">果泥</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">调味品</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">面食</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">饼干</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">菜籽油</td>
          <td class="et11" width="72">9</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">6.3</td>
        </tr>
        <tr>
          <td class="et11" width="332">橄榄油</td>
          <td class="et11" width="72">9</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">6.3</td>
        </tr>
        <tr>
          <td class="et11" width="332">亚麻籽油</td>
          <td class="et11" width="72">9</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">6.3</td>
        </tr>
        <tr>
          <td class="et11" width="332">天然蜂蜜</td>
          <td class="et11" width="72">9</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">6.3</td>
        </tr>
        <tr>
          <td class="et11" width="332">葡萄酒</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">10</td>
          <td class="et11" width="132">17.9</td>
        </tr>
        <tr>
          <td class="et11" width="332">葡萄汽酒</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">10</td>
          <td class="et11" width="132">17.9</td>
        </tr>
        <tr>
          <td class="et11" width="332">啤酒</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">即食燕窝</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" rowspan="10" width="108" height="332">化妆品</td>
          <td class="et11" width="332">面膜（片装）＜15元/片</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">面膜（片装）＞＝15元/片</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">15</td>
          <td class="et11" width="132">23.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">护肤品（非片装面膜）＜10元/g或＜10元/ml</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">护肤品（非片装面膜）＞＝10元/g或＞＝10元/ml</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">15</td>
          <td class="et11" width="132">23.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">化妆品/彩妆（片装）＜15元/片</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">化妆品/彩妆（片装）＞＝15元/片</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">15</td>
          <td class="et11" width="132">23.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">化妆品/彩妆（非片装）＜10元/g或＜10元/ml</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">化妆品/彩妆（非片装）＞＝10元/g或＞＝10元/ml</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">15</td>
          <td class="et11" width="132">23.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">香水＜10元/ml</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">香水＞＝10元/ml</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">15</td>
          <td class="et11" width="132">23.1</td>
        </tr>
        <tr>
          <td class="et11" rowspan="3" width="108" height="99">个护</td>
          <td class="et11" width="332">洗发水</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">沐浴露</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">牙膏</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" rowspan="14" width="108" height="465">3c</td>
          <td class="et11" width="332">手机、平板电脑</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">耳机、音箱</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">键盘、鼠标</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">滤水壶</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">美容仪</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">吹风机</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">电饭锅</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">咖啡机</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">面包机</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">榨汁机</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">电动剃须刀类</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">电动牙刷</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">相机及镜头</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">视频游戏及配件</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" rowspan="12" width="108" height="399">服装、轻奢</td>
          <td class="et11" width="332">女装、男装、内衣</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">包、钱包、箱包</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">贱金属仿首饰（项链、耳环等）</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">玻璃制仿首饰（项链、耳环等）</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">完税价格小于1万元手表</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">完税价格大于等于1万元手表</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">20</td>
          <td class="et11" width="132">28.9</td>
        </tr>
        <tr>
          <td class="et11" width="332">包贵金属制首饰</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">10</td>
          <td class="et11" width="132">17.9</td>
        </tr>
        <tr>
          <td class="et11" width="332">宝石或半宝石制品、天然或养殖珍珠制品</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">10</td>
          <td class="et11" width="132">17.9</td>
        </tr>
        <tr>
          <td class="et11" width="332">鞋</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">皮带</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">围巾</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">手机壳</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" rowspan="15" width="108" height="499">其它</td>
          <td class="et11" width="332">玩具</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">厨具</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">安全座椅</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">避孕套</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">0</td>
        </tr>
        <tr>
          <td class="et11" width="332">蒸汽眼罩</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">防蚊贴</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">退热贴</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">宠物食品（猫粮、狗粮）</td>
          <td class="et11" width="72">9</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">6.3</td>
        </tr>
        <tr>
          <td class="et11" width="332">节日用品</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">篮球、足球、排球</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">网球拍、羽毛球拍</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">溜冰鞋、旱冰鞋</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">健身及康复器械</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">渔具（钓鱼竿、钓鱼钩、钓线轮）</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">0</td>
          <td class="et11" width="132">9.1</td>
        </tr>
        <tr>
          <td class="et11" width="332">高尔夫球</td>
          <td class="et11" width="72">13</td>
          <td class="et11" width="72">10</td>
          <td class="et11" width="132">17.9</td>
        </tr>
      </tbody>
    </table>
    <h3><strong>&nbsp;</strong></h3>
  </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/11/15 10:57
 * @version 1.0
 * @description
 */
export default {
  name: 'POR'
}
</script>

<style scoped>

</style>
